import { useAppContext } from 'components/common/AppProvider';
import { ErrorBoundary } from 'components/common/ErrorBoundary';
import { Loading } from 'components/common/Loading';
import { FieldError, ValidatedInput } from 'components/common/Validation';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { RouteParams, RoutePaths } from 'routes/RoutePaths';
import { DynamicMenuItem, UpdateDynamicMenuItemRanksRequest } from 'services/ApiService/Insight/InsightApiClient';
import { deleteDynamicMenu, getDynamicMenus, updateDynamicMenu, updateDynamicMenuItemRanks } from 'store/DynamicMenus/DynamicMenusThunk';
import { InsightSelectors } from 'store/Normalizr/InsightSelectors';
import AddIcon from '@mui/icons-material/Add';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import DeleteIcon from '@mui/icons-material/Delete';
import * as Yup from 'yup';
import { EditDynamicMenuItem } from './EditDynamicMenuItem';
import { DragDropContext, Draggable, Droppable, DropResult } from '@hello-pangea/dnd';
import { sortBy } from 'lodash-es';
import { toastSuccess } from 'components/common/Toaster';
import { reorder } from 'services/Array';
import { differenceBy } from 'lodash-es';

export default (() => {
    const { dispatch, state: { entities } } = useAppContext();
    const navigate = useNavigate();

    const dynamicMenuId = Number(useParams<keyof RouteParams['DynamicMenus']['Edit']>().id);
    const location = useLocation();
    const editDynamicMenuItem = !!useParams<keyof RouteParams['DynamicMenus']['Item']['Edit']>().itemId;
    const addDynamicMenuItem = location.pathname.split('/').at(-1) === 'add';
    const dynamicMenu = InsightSelectors.getDynamicMenu(dynamicMenuId, entities.insight);

    const [isLoading, setIsLoading] = useState(true);
    const [itemOrder, setItemOrder] = useState(sortBy(dynamicMenu?.items, ['rank'])?.map(i => i.id) || []);
    const orderedItem = itemOrder.map(itemId => dynamicMenu?.items?.find(i => i.id === itemId));

    useEffect(() => {
        (async () => {
            try {
                const dm = await dispatch(getDynamicMenus());
                const results = differenceBy(dm?.find(i => i.id === dynamicMenuId)?.items, orderedItem, 'id');
                setItemOrder([...orderedItem as DynamicMenuItem[], ...results]?.map(i => i.id));
            } finally {
                setIsLoading(false);
            }
        })();
    }, [editDynamicMenuItem, addDynamicMenuItem]);

    const formik = useFormik<{ title: string }>({
        enableReinitialize: true,
        initialValues: {
            title: dynamicMenu?.name || '',
        },
        validationSchema: () => Yup.object({
            title: Yup.string()
                .required()
                .min(1)
                .max(50),
        }),
        onSubmit: async () => {
            const request: UpdateDynamicMenuItemRanksRequest = {
                itemRanks: itemOrder.map((itemId, i) => ({
                    id: itemId,
                    rank: i + 1,
                })),
            };

            if (formik.initialValues.title !== formik.values.title) {
                await dispatch(updateDynamicMenu(dynamicMenuId, formik.values.title));
            }

            if (request.itemRanks?.length !== 0) {
                await dispatch(updateDynamicMenuItemRanks(request));
            }
            dispatch(getDynamicMenus());
            toastSuccess('Menu updated');
        },
    });

    if (isLoading) {
        return <Loading />;
    }

    if (!dynamicMenu) {
        return null;
    }

    const handleDelete = async () => {
        await dispatch(deleteDynamicMenu(dynamicMenuId));
        dispatch(getDynamicMenus());
        navigate(RoutePaths.DynamicMenus.route);
    };

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }

        const ordered = reorder<number>(
            itemOrder ?? [],
            result.source.index,
            result.destination.index,
        );

        setItemOrder(ordered);
    };

    return <div className="d-flex flex-fill justify-content-center position-relative right-panel-container">
        <form onSubmit={formik.handleSubmit}>
            <div className="d-flex flex-column me-5 ">
                <h1 className="display-3 my-4">
                    Edit {dynamicMenu?.name}
                </h1>
                <h5 className="mt-2 text-secondary">Title</h5>
                <ValidatedInput
                    id="title"
                    type="text"
                    className="mb-3"
                    placeholder="title..."
                    value={formik.values.title}
                    touched={formik.touched.title}
                    error={formik.errors.title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                />
                <div>
                    <h5 className="mt-2 text-secondary">Items</h5>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                            {(provided) => (
                                <div {...provided.droppableProps} ref={provided.innerRef}>
                                    <ul className="list-group p-0">
                                        {orderedItem?.map((item, index) => (
                                            item && <Draggable key={index} draggableId={index.toString()} index={index}>
                                                {(provided, snapshot) => (
                                                    <React.Fragment>
                                                        <li ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            className={`d-flex bg-lvl2 p-3 my-3 ${snapshot.isDragging ? 'shadow' : ''}`}>
                                                            <span className="flex-fill">
                                                                <Link
                                                                    type="button"
                                                                    onClick={() => navigate(RoutePaths.DynamicMenus.Item.Edit.url(dynamicMenuId, item.id))}
                                                                    to={RoutePaths.DynamicMenus.Item.Edit.url(dynamicMenuId, item.id)}
                                                                >
                                                                    <span className="mt-1 ms-1" style={{ width: '15rem' }}>
                                                                        {item?.name}
                                                                        {item?.isDefault && <span className="badge badge-outline-info badge ms-2">Default</span>}
                                                                    </span>
                                                                </Link>
                                                            </span>
                                                            <span
                                                                {...provided.dragHandleProps}
                                                                className="d-flex justify-content-end">
                                                                <DragIndicatorIcon />
                                                            </span>
                                                        </li>
                                                    </React.Fragment>
                                                )}
                                            </Draggable>
                                        ))}
                                    </ul>
                                    {provided.placeholder}
                                    <li
                                        className="mb-3 card card-bordered d-flex justify-content-center p-2"
                                        style={{ flexBasis: '50%' }}
                                    >
                                        <button
                                            type="button"
                                            className="btn btn-flat-primary h-100"
                                            onClick={() => { navigate(RoutePaths.DynamicMenus.Item.Add.url(dynamicMenuId)); }}>
                                            <AddIcon className="text-muted" fontSize="large" />
                                        </button>
                                    </li>
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>

                    <FieldError
                        touched={!!formik.touched.title}
                        error={formik.errors.title}
                    />
                </div>
                <div className="d-flex justify-content-between my-5">
                    <button
                        className="btn btn-primary"
                        type="submit"
                        disabled={formik.isSubmitting}
                    >
                        Save
                    </button>

                    <button
                        className="btn btn-icon-text btn-outline-danger"
                        type="button"
                        disabled={formik.isSubmitting}
                        onClick={handleDelete}
                    >
                        <DeleteIcon fontSize="small" /> Delete
                    </button>
                </div>
            </div>
        </form >
        {
            (addDynamicMenuItem || editDynamicMenuItem) && !!dynamicMenu.items && < ErrorBoundary >
                <div className="bg-lvl1 border-start position-absolute" style={{
                    minHeight: '100%',
                    minWidth: '40vw',
                    right: 0,
                    top: 0,
                    zIndex: 1,
                }}>
                    <EditDynamicMenuItem
                        lastRank={Math.max(0, ...dynamicMenu.items.map(i => i.rank)) + 1}
                        className="position-sticky"
                    />
                </div>
            </ErrorBoundary>
        }
    </div>;
}) as React.FC;