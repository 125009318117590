import classNames from 'classnames';
import * as React from 'react';
import dayjs from 'dayjs';
import { ValidatedField } from './ValidatedField';
import { IValidatedDatePickerProps } from './ValidationModels';
import { JSX as SGBJSX } from '@sg-bootstrap/components';
import { defineCustomElement } from '@sg-bootstrap/components/dist/components/sg-date-picker';

declare global {
    namespace React {
        namespace JSX {
            interface IntrinsicElements {
                'sg-date-picker': SGBJSX.SgDatePicker & {
                    ondateSelected?: SGBJSX.SgDatePicker['onDateSelected'],
                    oninputCleared?: SGBJSX.SgDatePicker['onInputCleared'],
                };
            }
        }
    }
}

defineCustomElement();

export const ValidatedDatePicker: React.FC<IValidatedDatePickerProps> = (props) => {
    const { id, label, timezone, touched, error, required, className, value, onChange, ...inputProps } = props;
    const isInvalid = touched && !!error;
    const isValid = touched && !isInvalid;
    const inputClasses = classNames(
        {
            'is-invalid': !!isInvalid,
            'is-valid': !!isValid,
        },
    );

    delete inputProps.children;

    return <ValidatedField
        id={id}
        label={label}
        touched={touched}
        error={error}
        required={required}
        className={className}
    >
        <sg-date-picker
            value={value ? dayjs(value)?.startOf('day')?.toDate()?.getTime() : undefined}
            ondateSelected={({ detail: { date } }) => {
                const newDate = dayjs(date);
                onChange(newDate?.isValid() && newDate?.tz(timezone, true).startOf('day').format() || null);
            }}
            oninputCleared={() => {
                onChange(null);
            }}
            classes={inputClasses}
        />
    </ValidatedField>;
};