import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import classNames from 'classnames';
import { AddCategories } from 'components/common/AddCategories';
import { AddCategoryAssetClasses } from 'components/common/AddCategoryAssetClasses';
import { AddKeywords } from 'components/common/AddKeywords';
import { AddProducts } from 'components/common/AddProducts';
import { AddPubHeads } from 'components/common/AddPubHeads';
import { AddPublicationTypes } from 'components/common/AddPublicationTypes';
import { AddSectors } from 'components/common/AddSectors';
import { AddUniverses } from 'components/common/AddUniverses';
import { useAppContext } from 'components/common/AppProvider';
import { toastSuccess } from 'components/common/Toaster';
import { ValidatedDatePicker, ValidatedInput } from 'components/common/Validation';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import { RouteParams, RoutePaths } from 'routes/RoutePaths';
import {
    Brand,
    Category,
    CategoryAssetClass,
    DynamicMenuItem,
    Keyword,
    Language,
    Product,
    PublicationType,
    Sector,
    Universe,
} from 'services/ApiService/Insight/InsightApiClient';
import { LinkRegExp } from 'services/RegexService';
import {
    createDynamicMenuItem,
    deleteDynamicMenuItem,
    getDynamicMenus,
    updateDynamicMenuItem,
} from 'store/DynamicMenus/DynamicMenusThunk';
import { InsightSelectors } from 'store/Normalizr/InsightSelectors';
import * as Yup from 'yup';
import { AddLanguage } from 'components/common/AddLanguage';
import { AddBrands } from 'components/common/AddBrands';

interface IEditDynamicMenuItemProps {
    lastRank: number,
    className?: string;
    style?: React.CSSProperties;
}

export const EditDynamicMenuItem: React.FC<IEditDynamicMenuItemProps> = ({ lastRank, className, style }) => {
    const { dispatch, state: { entities } } = useAppContext();
    const location = useLocation();
    const navigate = useNavigate();
    const edit = useParams<keyof RouteParams['DynamicMenus']['Item']['Edit']>();
    const add = location.pathname.split('/').at(-1) === 'add';

    useEffect(() => {
        (async () => {
            await dispatch(getDynamicMenus());
        })();
    }, []);

    const isEdit = !add;
    const dynamicMenuId = Number(edit.id);
    const item: DynamicMenuItem | undefined = isEdit
        ? InsightSelectors.getDynamicMenuItem(Number(edit.itemId), entities.insight)
        : {
            id: 0,
            rank: lastRank,
            name: 'New item',
            isDefault: false,
        };
    const formik = useFormik<{
        name: string,
        isDefault: boolean,
        fetchByUniverseIds: number[] | undefined,
        fetchByResearchCategoryIds: number[] | undefined,
        fetchByProductIds: number[] | undefined,
        fetchBySectorIds: number[] | undefined,
        fetchByKeywordIds: number[] | undefined,
        fetchByCategoryAssetClassIds: number[] | undefined,
        fetchByPubHeadIds: number[] | undefined,
        filterBySectorIds: number[] | undefined,
        filterByKeywordIds: number[] | undefined,
        filterByPublicationTypes: PublicationType[] | undefined,
        filterByMinimumPages: number | undefined,
        filterByMaximumPages: number | undefined,
        filterByLanguage: string | undefined,
        filterByBrands: Brand[] | undefined,
        exclude: number[] | undefined,
        seeMoreLink: string,
        startDate: Date | undefined,
    }>({
        enableReinitialize: true,
        initialValues: {
            name: item?.name || '',
            isDefault: item?.isDefault || false,
            
            fetchByUniverseIds: item?.fetchBy?.universeIds || [],
            fetchByResearchCategoryIds: item?.fetchBy?.researchCategoryIds || [],
            fetchByProductIds: item?.fetchBy?.productIds || [],
            fetchBySectorIds: item?.fetchBy?.sectorIds || [],
            fetchByKeywordIds: item?.fetchBy?.keywordIds || [],
            fetchByCategoryAssetClassIds: item?.fetchBy?.assetClassIds || [],
            fetchByPubHeadIds: item?.fetchBy?.pubHeadIds || [],
            filterBySectorIds: item?.filter?.sectorIds || [],
            filterByKeywordIds: item?.filter?.keywordIds || [],
            filterByPublicationTypes: item?.filter?.publicationTypes || [],
            filterByMinimumPages: item?.filter?.minimumPages || undefined,
            filterByMaximumPages: item?.filter?.maximumPages || undefined,
            filterByLanguage: item?.filter?.language || undefined,
            filterByBrands: item?.filter?.brands || [Brand.SG],
            exclude: item?.exclude?.productIds || [],
            seeMoreLink: item?.seeMoreLink || '',
            startDate: item?.startDate || undefined,
        },
        validationSchema: () => Yup.object({
            name: Yup.string().required('Required').min(1),
            isDefault: Yup.bool().required('Required'),
            seeMoreLink: Yup.string().matches(LinkRegExp).required('Required'),
            startDate: Yup.date(),

            fetchByUniverseIds: Yup.array().of(Yup.number()),
            fetchByResearchCategoryIds: Yup.array().of(Yup.number()),
            fetchByProductIds: Yup.array().of(Yup.number()),
            fetchBySectorIds: Yup.array().of(Yup.number()),
            fetchByKeywordIds: Yup.array().of(Yup.number()),
            fetchByCategoryAssetClassIds: Yup.array().of(Yup.number()),
            fetchByPubHeadIds: Yup.array().of(Yup.number()),

            filterBySectorIds: Yup.array().of(Yup.number()),
            filterByKeywordIds: Yup.array().of(Yup.number()),
            filterByPublicationTypes: Yup
                .mixed<keyof typeof PublicationType>(),
            filterByMinimumPages: Yup.number().nullable().default(undefined).positive().integer().min(0).test({
                name: 'max > min',
                exclusive: false,
                params: {},
                message: 'Minimum Pages must be < than Maximum Pages',
                test: (value, context) => {
                    if (value && context.parent.filterByMaximumPages && value >= context.parent.filterByMaximumPages)
                        return false;
                    return true;
                },
            }),
            filterByMaximumPages: Yup.number().nullable().default(undefined).positive().integer().min(1).test({
                name: 'max > min',
                exclusive: false,
                params: {},
                message: 'Maximum Pages must be > than Minimum Pages',
                test: (value, context) => {
                    if (value && context.parent.filterByMinimumPages && value <= context.parent.filterByMinimumPages)
                        return false;
                    return true;
                },
            }),
            filterByBrands: Yup.array().required().min(1, 'Please provide at least one source'),
            exclude: Yup.array().of(Yup.number()),
        }),
        onSubmit: async values => {

            const filterByMinimumPages = formik.values.filterByMinimumPages != undefined && formik.values.filterByMinimumPages.toString() === '' ? undefined : formik.values.filterByMinimumPages;
            const filterByMaximumPages = formik.values.filterByMaximumPages != undefined && formik.values.filterByMaximumPages.toString() === '' ? undefined : formik.values.filterByMaximumPages;

            values.fetchByProductIds = values.fetchByProductIds?.map(item => parseInt(item.toString()));

            const dynamicMenuItem = {
                name: formik.values.name,
                rank: item?.rank,
                seeMoreLink: formik.values.seeMoreLink,
                isDefault: formik.values.isDefault,
                exclude: {
                    productIds: formik.values.exclude,
                },
                fetchBy: {
                    universeIds: formik.values.fetchByUniverseIds,
                    assetClassIds: formik.values.fetchByCategoryAssetClassIds,
                    researchCategoryIds: formik.values.fetchByResearchCategoryIds,
                    productIds: formik.values.fetchByProductIds,
                    keywordIds: formik.values.fetchByKeywordIds,
                    sectorIds: formik.values.fetchBySectorIds,
                    pubHeadIds: formik.values.fetchByPubHeadIds,
                },
                filter: {
                    keywordIds: formik.values.filterByKeywordIds,
                    sectorIds: formik.values.filterBySectorIds,
                    publicationTypes: formik.values.filterByPublicationTypes,
                    minimumPages: filterByMinimumPages,
                    maximumPages: filterByMaximumPages,
                    language: formik.values.filterByLanguage,
                    brands: formik.values.filterByBrands,
                },
                startDate: formik.values.startDate,
            } as DynamicMenuItem;

            if (isEdit && item?.id) {
                dynamicMenuItem.id = item.id;
                await dispatch(updateDynamicMenuItem(item.id, dynamicMenuItem));
            } else {
                await dispatch(createDynamicMenuItem(dynamicMenuId, dynamicMenuItem));
            }
            await dispatch(getDynamicMenus());
            toastSuccess('Configuration updated');
            navigate(RoutePaths.DynamicMenus.Edit.url(dynamicMenuId));
        },
    });

    const handleDelete = async () => {
        if (item?.id) {
            await dispatch(deleteDynamicMenuItem(item?.id));
            await dispatch(getDynamicMenus());
            toastSuccess(`${item.name} succesfully deleted`);
            navigate(RoutePaths.DynamicMenus.Edit.url(dynamicMenuId));
        }
    };

    if (!item || !dynamicMenuId) {
        return null;
    }

    const handleFetchByProductsChange = (products: Product[]) => {
        const { setFieldValue, setFieldTouched } = formik;
        setFieldTouched('fetchByProductIds', true, false);
        setFieldValue('fetchByProductIds', products.map(item => item.id));
    };

    const handleFetchByUniverseIdsChange = (universes: Universe[]) => {
        const { setFieldValue, setFieldTouched } = formik;
        setFieldTouched('fetchByUniverseIds', true, false);
        setFieldValue('fetchByUniverseIds', universes.map(item => item.id));
    };

    const handleFetchByCategoryIdsChange = (categories: Category[]) => {
        const { setFieldValue, setFieldTouched } = formik;
        setFieldTouched('fetchByResearchCategoryIds', true, false);
        setFieldValue('fetchByResearchCategoryIds', categories.map(item => item.id));
    };

    const handleFetchByCategoryAssetClassIdsChange = (categoryAssetClasses: CategoryAssetClass[]) => {
        const { setFieldValue, setFieldTouched } = formik;
        setFieldTouched('fetchByCategoryAssetClassIds', true, false);
        setFieldValue('fetchByCategoryAssetClassIds', categoryAssetClasses.map(item => item.id));
    };

    const handleExcludeChange = (products: Product[]) => {
        const { setFieldValue, setFieldTouched } = formik;
        setFieldTouched('exclude', true, false);
        setFieldValue('exclude', products.map(item => item.id));
    };

    const handleFetchBySectorIdsChange = (sectors: Sector[]) => {
        const { setFieldValue, setFieldTouched } = formik;
        setFieldTouched('fetchBySectorIds', true, false);
        setFieldValue('fetchBySectorIds', sectors.map(item => item.id));
    };

    const handleFetchByKeywordIdsChange = (keywords: Keyword[]) => {
        const { setFieldValue, setFieldTouched } = formik;
        setFieldTouched('fetchByKeywordIds', true, false);
        setFieldValue('fetchByKeywordIds', keywords.map(item => item.id));
    };

    const handleFetchByPubHeadIdsChange = (pubHeadIds: number[]) => {
        const { setFieldValue, setFieldTouched } = formik;
        setFieldTouched('fetchByPubHeadIds', true, false);
        setFieldValue('fetchByPubHeadIds', pubHeadIds);
    };

    const handleFilterBySectorIdsChange = (keywords: Sector[]) => {
        const { setFieldValue, setFieldTouched } = formik;
        setFieldTouched('filterBySectorIds', true, false);
        setFieldValue('filterBySectorIds', keywords.map(item => item.id));
    };

    const handleFilterByKeywordIdsChange = (keywords: Keyword[]) => {
        const { setFieldValue, setFieldTouched } = formik;
        setFieldTouched('filterByKeywordIds', true, false);
        setFieldValue('filterByKeywordIds', keywords.map(item => item.id));
    };

    const handleFilterByLanguageChange = (language: Language) => {
        const { setFieldValue, setFieldTouched } = formik;
        setFieldTouched('filterByLanguage', true, false);
        setFieldValue('filterByLanguage', language?.isoCode);
    };

    const handleFilterByPublicationTypesChange = (publicationTypes: PublicationType[]) => {
        const { setFieldValue, setFieldTouched } = formik;
        setFieldTouched('filterByPublicationTypes', true, false);
        setFieldValue('filterByPublicationTypes', publicationTypes);
    };

    const handleIsDefaultChange = () => {
        const { setFieldValue, setFieldTouched } = formik;
        setFieldTouched('isDefault', true, false);
        setFieldValue('isDefault', !formik.values.isDefault);
    };

    const handleStartDateChange = (date: string | null) => {
        const { setFieldValue, setFieldTouched } = formik;
        setFieldTouched('startDate', true, false);
        setFieldValue('startDate', date || undefined);
    };

    const handleBrandsChange = (brands: Brand[]) => {
        const { setFieldValue, setFieldTouched } = formik;
        setFieldTouched('filterByBrands', true, false);
        setFieldValue('filterByBrands', brands);
    };

    const classes = classNames('d-flex flex-column w-100 p-4 flex-grow-0', className);
    return <div className={classes} style={style}>
        <div className="d-flex justify-content-end mb-3">
            <NavLink to={RoutePaths.DynamicMenus.Edit.url(dynamicMenuId)} className="text-muted">
                <CloseIcon className="text-muted" fontSize="small" />
            </NavLink>
        </div>
        <form className="fullwidth-form" onSubmit={formik.handleSubmit}>
            <ValidatedInput
                id="name"
                type="text"
                label="Name"
                placeholder="Name"
                value={formik.values.name}
                touched={!!formik.touched.name}
                error={formik.errors.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
            />
            <ValidatedInput
                id="isDefault"
                label="Is default"
                type="checkbox"
                placeholder="Is default"
                value={formik.values.isDefault}
                touched={formik.touched.isDefault}
                error={formik.errors.isDefault}
                onChange={handleIsDefaultChange}
                checked={formik.values.isDefault}
                onBlur={formik.handleBlur}
                required
            >
                {<option key="isDefault" />}
            </ValidatedInput>

            <ValidatedInput
                id="seeMoreLink"
                type="text"
                label="See more link"
                placeholder="See more link"
                value={formik.values.seeMoreLink}
                touched={!!formik.touched.seeMoreLink}
                error={formik.errors.seeMoreLink}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required
            />

            <ValidatedDatePicker
                id="startDate"
                label="Start Date"
                touched={!!formik.values.startDate}
                error={formik.errors.startDate}
                value={formik.values.startDate}
                onChange={handleStartDateChange}
                timezone="utc"
            />

            <h5 className="mt-5 text-secondary">Fetch by</h5>
            <AddProducts
                id="fetchByProductIds"
                label="Products"
                selected={formik.values.fetchByProductIds ?? []}
                onChange={handleFetchByProductsChange}
                touched={formik.touched.fetchByProductIds}
                errors={formik.errors.fetchByProductIds}
                placeholder="Select one or multiple products"
            />
            <AddUniverses
                id="fetchByUniverseIds"
                label="Universes"
                touched={formik.touched.fetchByUniverseIds}
                errors={formik.errors.fetchByUniverseIds}
                onChange={handleFetchByUniverseIdsChange}
                placeholder="Select one or multiple universes"
                selected={formik.values.fetchByUniverseIds ?? []}
            />
            <AddCategories
                id="fetchByCategories"
                label="Categories"
                placeholder="Select one or multiple categories"
                touched={formik.touched.fetchByResearchCategoryIds}
                errors={formik.errors.fetchByResearchCategoryIds}
                onChange={handleFetchByCategoryIdsChange}
                selected={formik.values.fetchByResearchCategoryIds ?? []}
            />
            <AddSectors
                id="fetchBySectorIds"
                label="Sectors"
                placeholder="Select one or multiple sectors"
                touched={formik.touched.fetchBySectorIds}
                errors={formik.errors.fetchBySectorIds}
                onChange={handleFetchBySectorIdsChange}
                selected={formik.values.fetchBySectorIds ?? []}
            />
            <AddKeywords
                id="fetchByKeywordIds"
                label="Keywords"
                placeholder="Select one or multiple keywords"
                touched={formik.touched.fetchByKeywordIds}
                errors={formik.errors.fetchByKeywordIds}
                onChange={handleFetchByKeywordIdsChange}
                selected={formik.values.fetchByKeywordIds ?? []}
            />
            <AddCategoryAssetClasses
                id="fetchByCategoryAssetClassIds"
                label="Asset Classes"
                placeholder="Select one or multiple category asset classes"
                touched={formik.touched.fetchByCategoryAssetClassIds}
                errors={formik.errors.fetchByCategoryAssetClassIds}
                onChange={handleFetchByCategoryAssetClassIdsChange}
                selected={formik.values.fetchByCategoryAssetClassIds ?? []}
            />
            <AddPubHeads
                id="fetchByPubheadIds"
                label="Pub Heads"
                placeholder="Select one or multiple Pub Heads"
                touched={formik.touched.fetchByPubHeadIds}
                errors={formik.errors.fetchByPubHeadIds}
                onChange={handleFetchByPubHeadIdsChange}
                selected={formik.values.fetchByPubHeadIds ?? []}
            />

            <h5 className="mt-5 text-secondary">Filter by</h5>
            <AddBrands
                id="filterByBrands"
                label="Sources"
                selected={formik.values.filterByBrands ?? []}
                onChange={handleBrandsChange}
                touched={formik.touched.filterByBrands}
                errors={formik.errors.filterByBrands}
                placeholder="Select one or multiple sources"
            />
            <AddSectors
                id="filterBySectorIds"
                label="Sectors"
                placeholder="Select one or multiple sectors"
                touched={formik.touched.filterBySectorIds}
                errors={formik.errors.filterBySectorIds}
                onChange={handleFilterBySectorIdsChange}
                selected={formik.values.filterBySectorIds ?? []}
            />

            <AddKeywords
                id="filterByKeywordIds"
                label="Keywords"
                placeholder="Select one or multiple keywords"
                touched={formik.touched.filterByKeywordIds}
                errors={formik.errors.filterByKeywordIds}
                onChange={handleFilterByKeywordIdsChange}
                selected={formik.values.filterByKeywordIds ?? []}
            />

            <AddPublicationTypes
                id="filterByPublicationTypes"
                label="Publication Types"
                placeholder="Select one or multiple publication types"
                touched={formik.touched.filterByPublicationTypes}
                errors={formik.errors.filterByPublicationTypes}
                onChange={handleFilterByPublicationTypesChange}
                selected={formik.values.filterByPublicationTypes ?? []}
            />

            <ValidatedInput
                id="filterByMinimumPages"
                type="number"
                label="Minimum Pages"
                placeholder="Minimum pages number"
                value={formik.values.filterByMinimumPages ?? ''}
                touched={!!formik.touched.filterByMinimumPages}
                error={formik.errors.filterByMinimumPages}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                min="0"
            />

            <ValidatedInput
                id="filterByMaximumPages"
                type="number"
                label="Maximum Pages"
                placeholder="Maximum pages number"
                value={formik.values.filterByMaximumPages ?? ''}
                touched={!!formik.touched.filterByMaximumPages}
                error={formik.errors.filterByMaximumPages}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                min="1"
            />

            <AddLanguage
                id="filterByLanguage"
                label="Language"
                placeholder="Select one language"
                touched={formik.touched.filterByLanguage}
                errors={formik.errors.filterByLanguage}
                onChange={handleFilterByLanguageChange}
                selected={formik.values.filterByLanguage ?? ''}
            />

            <h5 className="mt-5 text-secondary">Exclude</h5>
            <AddProducts
                label="Exclude"
                id="Products"
                placeholder="Select one or multiple products"
                touched={formik.touched.exclude}
                errors={formik.errors.exclude}
                onChange={handleExcludeChange}
                selected={formik.values.exclude ?? []}
            />

            <div className="d-flex justify-content-between mt-3">
                <button
                    className="btn btn-icon-text btn-outline-danger"
                    type="button"
                    disabled={formik.isSubmitting || item.id === 0}
                    onClick={handleDelete}
                >
                    <DeleteIcon fontSize="small" /> Delete
                </button>
                <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={formik.isSubmitting}
                >
                    Save
                </button>
            </div>
        </form>

    </div>;
};
