import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'react-app-polyfill/ie11';

import 'bootstrap';
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { configureI18n } from 'config/i18n';
import { requestAuthorization } from 'services/SgConnect';
import { AppProvider } from 'components/common/AppProvider';
import { ErrorBoundary } from 'components/common/ErrorBoundary';
import { MaterialUiProvider } from 'components/common/MaterialUiProvider';
import NotAuthorized from 'components/Pages/Errors/NotAuthorized';
import { AppRoutes } from './routes';
import { configureRum } from 'config/rum';

import './css/bootstrapTheming.scss';
import './css/site.scss';

function removeInitLoader() {
    const appInitLoader = document.getElementById('app-init-loader');
    if (appInitLoader) {
        appInitLoader.remove();
    }
}

async function start() {
    const App = (children: React.ReactNode) => <AppProvider>
        <MaterialUiProvider>
            <ErrorBoundary>
                <BrowserRouter>
                    {children}
                </BrowserRouter>
            </ErrorBoundary>
        </MaterialUiProvider>
    </AppProvider>;

    const renderApp = () => {
        const container = document.getElementById('root');
        const root = createRoot(container!);
        root.render(App((<AppRoutes />)));
        removeInitLoader();
    };

    configureRum();

    await configureI18n();

    requestAuthorization()
        .then(() => {
            renderApp();
        })
        .catch(error => {
            if (error?.notOnLan) {
                const container = document.getElementById('root');

                const root = createRoot(container!);
                root.render(App((<NotAuthorized message={error.message} className="fullpage" />)));
            }
            removeInitLoader();
        });
}

start();
